<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import appConfig from "@/app.config";
    import Swal from 'sweetalert2';



    /**
     * Add-product component
     */
    export default {
        page: {
            title: "Locations",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
          
        },
   
        data() {
            return {
             
                title: "Locations",
                locations:[],
                items: [
                    {
                        text: "Dashboard",
                    },
                    {
                        text: "Locations",
                        active: true,
                    },
                ],
                loading: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                search:'',
                table_data : {
                    draw: 0,
                    length: 10,
                    search:'',
                    paginated: true
                },
                fields: [
                    {
                        key: "name",
                        label: "Location Name",
                        sortable: true,
                    },
                    {
                        label: "Start Day",
                        key: "start_day",
                        sortable: true,
                    },
                    {
                        label: "Lock Date",
                        key: "lock_date",
                        sortable: true,
                    },
                  
                    {
                        key: "address.address",
                        label: "Address",
                        sortable: true,
                    },
                    {
                        key: "address.state",
                        label: "State",
                        sortable: true,
                    },
                        
                        "time_zone",
                        "manager",
                        {
                        key: "created_at",
                        label: "Created At",
                        sortable: true,
                        formatter:(key) => {
                            return this.DateWithMonthName(key , 'ddd, MMM DD,YYYY hh:mm A')
                        },
                    },
                    "action",
                ],
            };
        },

 watch:{
     currentPage:{
         handler:function(currentPage,pre_currentPage){
             if(currentPage>pre_currentPage){
                 this.table_data.length+=this.perPage
                 this.fetchLocations();
             }
         }
     },
     search:{
         handler:function(search,pre_search){
                 this.table_data.search=search
                 this.fetchLocations();
             
         }
     },
 },

  mounted() {
    this.fetchLocations();
//    console.log(this.changeDateTimeTimeZone(new Date(),"Pacific/Niue"))
  },
  methods: {
      fetchLocations(url) {
        url = url ?? '/restaurant/location/index';
        this.loading = true;
        this.table_data.draw++;
        this.$axios.get(url,{params: this.table_data})
        .then(response => {
            let data = response.data.payload;
            this.locations=data.paginated;   
            this.loading = false            
        }).catch(error => {
            this.handleErrorResponse(error.response, "error");
        })
        .finally(() => (this.loading = false));   
               
    },
    onFiltered(filteredItems) {
     console.log(filteredItems);
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteLocation(id){
      Swal.fire({
        title: "Are you sure?",
        text: "Please confirm that you understand what will happen by deleting this location. This location will become inaccessible for the entire organization,  All employees will be removed from this location also Future shifts for this location will be deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
              this.$axios.get('/restaurant/location/delete/'+id)
                .then(response => {
                    let data = response.data;
                //    this.triggerSwal(data.message, "success");
                    this.locations.data = this.locations.data.filter((item) => (item.id != id));
                                     
                    Swal.fire("Deleted!", data.message, "success");
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
        }
      }); 
        
    },

      arrayToString(employees) {
      let name_array = employees.map((employee) => {
        return employee.user.name;
      });
      let string = name_array.toString();

      return string;
    }, 

  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
    <div class="card">
    <div class="card-body">
    <router-link to="/create-location" ><b-button class="gradientColor border-0 radius-20 px-4 float-end"> Create Location </b-button></router-link>
        <div class="row col-12">
            <div class="col-md-4 mx-auto me-0">
                <b-form-group id="input-group-2" label="Search" label-for="input-2">
                    <b-form-input id="input-2" v-model="search" placeholder="Search" ></b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-12 mb-0">
                <div class="table-responsive mb-0">
                    <b-table
                        table-class="table table-centered datatable mt-3 table-bordered"
                        thead-tr-class="bg-transparent"
                        :items="locations.data"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="loading"
                        show-empty
                    >
                        <template #table-busy>
                            <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                        </template>
                        <template v-slot:cell(name)="data">
                            {{ data.item.name }}
                        </template>
                        <template v-slot:cell(time_zone)="data">
                            {{getSafe(() => data.item.time_zone.text)}}
                        </template>
                        <template v-slot:cell(manager)="data">
                            {{arrayToString(data.item.employees) }}
                        </template>
                        
                        <template v-slot:cell(action)="data">
                            <ul class="d-flex list-inline mb-0">
                                <li class="list-inline-item">
                                    <router-link v-b-tooltip.hover title="Edit" :to="{ name: 'Edit_Location', params: { location_id: data.item.id }}">
                                        <i class="uil uil-pen font-size-18"></i>
                                    </router-link>
                                </li>
                                <li class="list-inline-item">
                                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover @click="deleteLocation(data.item.id)" title="Delete">
                                        <i class="uil uil-trash-alt font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="col-sm-12 col-md-12 mt-3">
                <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination justify-content-end py-0 my-0 gap-2">
                    <li
                        class="paginate_button page-item previous"
                        :class="locations.prev_page_url == null ? 'disabled' : ''"
                    >
                        <button
                        type="button"
                        :class="locations.prev_page_url == null ? 'disabled' : ''"
                        @click="fetchLocations(locations.prev_page_url)"
                        class="btn bg-light text-dark"
                        >
                        <i class="fas fa-arrow-left"></i>
                        </button>
                    </li>

                    <li
                        class="paginate_button page-item next"
                        :class="locations.next_page_url == null ? 'disabled' : ''"
                    >
                        <button
                        type="button"
                        :class="locations.next_page_url == null ? 'disabled' : ''"
                        @click="fetchLocations(locations.next_page_url)"
                        aria-controls="DataTables_Table_0"
                        data-dt-idx="8"
                        tabindex="0"
                        class="btn bg-light text-dark"
                        >
                        <i class="fas fa-arrow-right"></i>
                        </button>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </Layout>
</template>
